import TopBar from '../components/Topbar';

//PID: 5
export default function DataRoom() {
  return (
    <div>
        <TopBar/>
        <h1>자료실</h1>
    </div>
  );
}
