import TopBar from '../components/Topbar';

//PID: 1
export default function Introduction() {
  return (
    <div>
        <TopBar/>
        <h1>소개</h1>
    </div>
  );
}
