import TopBar from '../components/Topbar';

//PID: 7
export default function Help() {
    return (
      <div>
          <TopBar/>
          <h1>문의</h1>
      </div>
    );
  }
  