//PID: 4
import { LocalStorageClass } from '../../Util';
import { Redirect } from "../../Util";
import Swal from "sweetalert2";

export default function Logout() {
    const LSC = new LocalStorageClass();
    if (LSC.get("Id") === null) {
        Swal.fire({
            title: "<span style=\"font-family: SeoulNamsanM, monospace\">오류</span>",
            text: "잘못된 접근입니다.",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "확인"
        }).then(() => {
            Redirect(".");
        });
    } else {
        LSC.remove("Id");
        LSC.remove("Pw");
        LSC.set("event", "logout")
        Redirect(".", false);
    }
}
