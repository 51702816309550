import * as React from 'react';
import { LocalStorageClass } from "../Util";
import { Alert, AlertTitle, Box, Typography, Snackbar } from '@mui/material';

import Topbar from '../components/Topbar';

// PID: 0
export default function Main() {
    const LSC = new LocalStorageClass();
    let success = null;
    let [alertopen, setAlertopen] = React.useState(true);
    const closeEvent = (e, r) => {
        if (r === "clickaway") return;
        setAlertopen(false);
    }

    if (LSC.get("event") !== null) {
        const target = LSC.get("event") === "login" ? "로그인" : "로그아웃";
        success = (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={3500}
                open={alertopen}
                onClose={closeEvent}
                sx={{ paddingLeft: "1%", paddingRight: "1%" }}
            >
                <Alert>
                    <AlertTitle>
                        <Typography sx={{ fontFamily: "SeoulNamsanEB", fontSize: "1.2rem" }}>
                            성공
                        </Typography>
                    </AlertTitle>
                    <Typography sx={{ fontFamily: "SeoulNamsanC" }}>{target}이 성공적으로 완료되었습니다!</Typography>
                </Alert>
            </Snackbar>
        );
        LSC.remove("event");
    }

    return (
        <React.Fragment>
            <Topbar/>
            { success }
            <Box>

            </Box>
        </React.Fragment>
    );
}