import * as React from "react";

import { Box, Button, Typography } from "@mui/material";
import { LocalStorageClass, Redirect, GetPid } from "../Util";
import logo from "../files/topbar_logo.png";

import Swal from "sweetalert2";

export default function TopBar() {
  const LSC = new LocalStorageClass();

  const items = [
    {
      name: "소개",
      href: ".?pid=1",
    },
    {
      name: "갤러리",
      href: ".?pid=2",
    },
    {
      name: "자료실",
      href: ".?pid=5",
    },
    {
      name: "문의",
      href: ".?pid=7",
    },
  ];

  let itemComponents = [];
  items.map((e) => {
    if (e.href.toString().split("=")[1] === GetPid()) {
      itemComponents.push(
        <Typography
          key={e.name}
          sx={{
            mt: 1.5,
            ml: 3,
            fontSize: "1.2rem",
            fontFamily: "SeoulNamsanM",
            cursor: "pointer",
            color: "gray",
          }}
          onClick={() => {
            Redirect(e.href, false);
          }}
        >
          {e.name}
        </Typography>
      );
    } else {
      itemComponents.push(
        <Typography
          key={e.name}
          sx={{
            mt: 1.5,
            ml: 3,
            fontSize: "1.2rem",
            fontFamily: "SeoulNamsanM",
            cursor: "pointer",
            "&:hover": { color: "gray" },
          }}
          onClick={() => {
            Redirect(e.href, false);
          }}
        >
          {e.name}
        </Typography>
      );
    }
    return null;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #D1D1D1",
        alignItems: "center",
      }}
    >
      <img
        onClick={() => {
          Redirect(".", false);
        }}
        src={logo}
        alt="logo"
        style={{
          width: 300,
          marginTop: 15,
          marginBottom: 15,
          cursor: "pointer",
        }}
      />
      <Typography
        sx={{
          mt: 1.5,
          ml: 3,
          fontSize: "1.2rem",
          fontFamily: "SeoulNamsanM",
          cursor: "pointer",
          fontWeight: "bold",
          color: "#71b581",
        }}
        onClick={() => Redirect(".")}
      >
        홈
      </Typography>
      {itemComponents}
      {LSC.get("Id") === null ? (
        <Button
          variant="outlined"
          onClick={() => {
            Redirect(".?pid=3", false);
          }}
          sx={{
            width: 100,
            height: 50,
            display: "flex",
            ml: "auto",
            mr: 5,
            fontFamily: "SeoulNamsanM",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
          className="LoginButton"
        >
          로그인
        </Button>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              ml: "auto",
            }}
          >
            <Typography
              sx={{
                fontFamily: "SeoulNamsanM",
                fontWeight: "bold",
                fontSize: "1.2rem",
                mr: 0.7,
              }}
            >
              {LSC.get("Id")}
            </Typography>
            <Typography
              sx={{ fontFamily: "SeoulNamsanL", fontSize: "1.2rem", mr: 5 }}
            >
              님
            </Typography>
          </Box>
          <Button
            className="LogoutButton"
            variant="outlined"
            onClick={() => {
              Swal.fire({
                title:
                  '<span style="font-family: SeoulNamsanM, monospace">정말 로그아웃 하시겠습니까?</span>',
                icon: "question",
                toast: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText:
                  '<span style="font-family: SeoulNamsanM, monospace">로그아웃</span>',
                confirmButtonColor: "green",
                cancelButtonText:
                  '<span style="font-family: SeoulNamsanM, monospace">취소</span>',
                cancelButtonColor: "red",
              }).then((response) => {
                if (response.isConfirmed) Redirect(".?pid=4")
              });
            }}
            sx={{
              width: 100,
              height: 50,
              display: "flex",
              mr: 5,
              fontFamily: "SeoulNamsanM",
              fontWeight: "bold",
              fontSize: "1.1rem",
              color: "rgb(255, 41, 209)",
              borderColor: "rgb(255, 41, 209)",
            }}
          >
            로그아웃
          </Button>
        </React.Fragment>
      )}
    </Box>
  );
}
