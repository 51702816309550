import TopBar from '../components/Topbar';

//PID: 2
export default function Gallery() {
  return (
    <div>
        <TopBar/>
        <h1>갤러리</h1>
    </div>
  );
}
